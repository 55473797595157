export type _360CameraParams = {
  azimuthAngle: number
  polarAngle: number
}

export type _360Floor = {
  floorID: string
  name: string
}

export type _360Scene = {
  date: string // DD-MM-YYYY
  dateID: string
  floors: _360Floor[]
  polarAngle: number
  azimuthAngle: number
}

export type _360ProjectJsonResponse = {
  dates: _360Scene[]
  [key: string]: any
}

export type MobileCaptureSnapshot = {
  id: string
  uploadedBy: string
  updatedAt: string //YYYY-MM-DDTHH:mm:SS.MS (ISO)
  assetType: string //mobile_photos or mobile_audios
  thumbnailUrl: string //presigned S3 URL
}

export enum _360UrlParams {
  DateId = "date_id",
  Floor = "floor",
  Marker = "marker",
  PolarAngle = "polarAngle",
  AzimuthAngle = "azimuthAngle",
}
