import type { DateTime } from "@evercam/shared/types/time"

export type User = {
  id?: number
  fullname?: string
  firstname: string
  lastname: string
  email: string
  telephone: string
  country: string
  isAdmin: boolean
  persona: Persona
  companyId?: string
  companyName?: string
  companyExid?: string
  features?: Array<UserFeatureFlag>
}

export type AdminUser = {
  id: number
  fullname: string
  firstname: string
  email: string
  telephone: string
  country: string
  isAdmin: boolean
  companyId: string
  companyName: string
  companyExid: string
  features: Array<UserFeatureFlag>
  active: boolean
  cameraShares: number
  camerasCount: number
  camerasOwned: number
  countryId: number
  countryName: string
  createdAt: DateTime
  integrations: string[]
  lastEventBrowser: string
  lastEvenCreatedAt: DateTime
  lastEventId: number
  lastEventIpAddress: string
  lastEventName: string
  lastEventOs: string
  lastLoginAt: string
  lastname: string
  persona: string
  projects: string[]
  projectsCount: number
  referralUrl?: string
  snapmailCount: number
  zohoId: string
}

export enum UserFeatureFlag {
  CompanyAdmin = "company-admin",
  VideoWalls = "video-walls",
  MassCameraSharing = "mass-camera-sharing",
  Copilot = "copilot",
}

export type ProjectUser = User & {
  lastSeenAt: string
  active: boolean
  activeOneMonth: boolean
  eventsCount: string
  cameraExid: string
  cameraRights: string
}

export enum Persona {
  HQ = "HQ",
  PM = "PM",
  QS = "QS",
  Marketing = "Marketing",
  HS = "H&S",
  DI = "DI",
  Other = "Other",
}

export type UpdatePasswordRequestPayload = {
  token: string
  password: string
}

export type Credentials = {
  apiKey: string
  apiId: string
}

export type LoginRequestPayload = {
  username: string
  password: string
  redirectToZoho?: boolean
}

export type RemotePasswordUpdateRequestPayload = {
  currentPassword: string
  newPassword: string
}

export type LoginResponsePayload = {
  confirmedAt?: string
  country?: string
  createdAt?: string
  email?: string
  firstname?: string
  id?: string
  isAdmin?: boolean
  lastLoginAt?: string
  lastname?: string
  persona?: Persona
  telephone?: string
  token?: string
  updatedAt?: string
  evercamAccount?: string
}

export type LogoutParams = {
  token: string
  fromAdmin?: boolean
}

export type UserSession = {
  browser: string
  createdAt: string
  id: number
  ipAddress: string
  isCurrent: boolean
  lastUsedAt: string
  os: string
}

export type SessionsListRequestPayload = {
  authorization: string
  browser?: string
  os?: string
  ipAddress?: string
  sort: string
  limit: number
  page: number
}

export type SessionActivityCamSettingsDetail = {
  new: any
  old: any
}

export type SessionActivityDetail = {
  agent: string
  camSettings: SessionActivityCamSettingsDetail
  country: string
  countryCode: string
  ip: string
}

export type SessionActivity = {
  cameraExid: string
  details: SessionActivityDetail
  insertedAt: string
  type: string
  who: string
}

export type SessionActivitiesListRequestPayload = {
  limit: number
  from: string
  to: string
}

export type UserUpdateRequestPayload = {
  firstname?: string
  lastname?: string
  email?: string
  telephone?: string
  country?: string
  persona?: string
  apiKey?: string
  apiId?: string
}

export type UserDeleteRequestPayload = {
  verifEmail: string
}

export type GoogleAuthRequestPayload = { code: string; redirectUri: string }
