import { AdminCamera, Camera } from "@evercam/shared/types/camera"
import type { EntityByExid, Exid } from "./index"

export type ProjectExid = Exid

export type ProjectsByExid = EntityByExid<Project>

export type Project = {
  id: string | number
  exid: ProjectExid
  name: string
  status?: ProjectStatus
  insertedAt?: string
  startedAt?: string
  timezone?: string
  zohoId?: string
  cameras?: Array<Camera | AdminCamera>
  featureFlags: Array<ProjectFeatureFlag>
  owner?: { email?: string }
}

export enum ProjectStatus {
  ToStart = "To Start",
  InProgress = "In Progress",
  Completed = "Completed",
  NotAplicable = "Not Applicable",
}

export type ProjectResponsePayload = {
  data?: Project[]
}

export type Logo = {
  name: string
  url: string
}

export type ProjectLogoResponsePayload = Logo[]

export enum ProjectFeatureFlag {
  AnprLegacy = "anpr_legacy",
  DroneView = "drone_view",
  Bim = "4d_bim",
  BimItwin = "4d_bim_itwin",
  BimForge = "4d_bim_forge",
  ThreeSixtyView = "360_view",
  NdaMc = "NDA/MC",
  GateReport = "gate_report",
  GateReportAutoVerify = "auto_publish",
  GateReportMotionDetection = "motion_detection",
  FullEmpty = "full_empty",
  MediaHubSharing = "archives_sharing",
  GateReportAnprBased = "anpr_sourced",
  NoCameras = "no_cameras",
}
