import type { CameraExid } from "./camera"
import type { ProjectExid } from "./project"

export enum TimelapseReportType {
  Safety = "safety",
  Construction = "construction",
  SiteActivity = "siteActivity",
}

export enum TimelapseType {
  Hour = "hour",
  Day = "day",
  Week = "week",
  Month = "month",
  Year = "year",
}

export enum TimelapseReportSeverity {
  Low = "low",
  Medium = "medium",
  High = "high",
}

export enum TimelapseActivityType {
  Workers = "workers",
  Equipment = "equipment",
  Vehicles = "vehicles",
}

export type TimelapseReport = {
  id: number
  cameraExid: CameraExid
  projectExid: ProjectExid
  timelapseId: number
  timelapseType: TimelapseType
  type: TimelapseReportType
  fromDate: string
  toDate: string
  title: string
  region: string | null
  description: string | null
  level: TimelapseReportSeverity | null
  activityType: TimelapseActivityType | null
  activityCount: number | null
  insertedAt: string
  updatedAt: string
}

export type TimelapseReportFilters = {
  projectExid: ProjectExid
  cameraExid?: CameraExid
  fromDate: string
  toDate: string
  activityType?: TimelapseActivityType
  severity?: TimelapseReportSeverity
  timelapseType: TimelapseType
  reportType: TimelapseReportType
  confidence?: number
}

export type TimelineEventCount = {
  date: string
  counts: Record<string, number>
}

export type CopilotTimelapse = {
  id: number
  url: string
  fromDate: Date | string
  toDate: Date | string
  cameraExid: CameraExid
  projectExid: ProjectExid
  type: TimelapseType
}

export type CopilotTimelapsePeriod = {
  id: number
  timestamp: Date | string
}

export type CopilotTimelapsesAvailabilityByPeriod = {
  month: CopilotTimelapsePeriod[]
  week: CopilotTimelapsePeriod[]
  day: CopilotTimelapsePeriod[]
  hour: CopilotTimelapsePeriod[]
}
